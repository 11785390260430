import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import LoadingOverlay from "react-loading-overlay";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { withRouter } from "react-router-dom";
import SynopsLoader from "./SynopsLoader";
import { LocalApiBaseUrl } from "./Constant";
import axiosInstance from "./interceptor";

function AssetAdoptionFilter(props) {
  const [showLoaderOnPage, setShowLoaderOnPage] = useState(false);
  const [omids, setOmids] = useState([]);

  useEffect(() => {
    const getL4OfferingsAndOMIDs = (data) => {
      if (!Array.isArray(data)) {
        return [];
      }

      try {
        return data.flatMap((client) => {
          if (!client.Level1Offering) return [];

          return client.Level1Offering.flatMap((l1) => {
            if (!l1.Level2Offering) return [];

            return l1.Level2Offering.flatMap((l2) => {
              if (!l2.Level3Offering) return [];

              return l2.Level3Offering.flatMap((l3) => {
                if (!l3.Level4Offering) return [];

                return l3.Level4Offering.map((l4) => ({
                  offeringName: l4.OfferingNameL4 || "",
                  omids: Array.isArray(l4.OMIDs) ? l4.OMIDs : [],
                  role: Array.isArray(l4.roleList) ? l4.roleList : [],
                }));
              });
            });
          });
        });
      } catch (error) {
        console.error("Error processing offerings:", error);
        return [];
      }
    };
    if (props.contract && props.contract !== "Select Contract" && props.client) {
      setShowLoaderOnPage(true);
      axiosInstance
        .get(
          `${LocalApiBaseUrl}AssetInventory/GetCorebeatOfferingData?ClientName=${encodeURIComponent(
            props.client
          )}&ContractNumber=${props.contract}`
        )
        .then((res) => {
          setShowLoaderOnPage(false);
          setOmids(getL4OfferingsAndOMIDs(res.data));
          props.onSaveOfferingData(res.data[0]);
        });
    } else {
      props.onSaveAllOffering([]);
    }
  }, [props.contract, props.client]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const clientFromQuery = params.get("client");
    const contractFromQuery = params.get("contract");
    const offeringFromQuery = params.get("offering");
    const offeringSSGFromQuery = params.get("offeringSSG");
    const subOfferingFromQuery = params.get("subOffering");
    const processFromQuery = params.get("process");
    
    if(clientFromQuery)
      props.onSaveClient(clientFromQuery);
    if(contractFromQuery)
      props.onSaveContract(contractFromQuery);
    if(offeringFromQuery)
      props.onSaveMainOffering(offeringFromQuery);
    if(offeringSSGFromQuery)
      props.onSaveOfferingSSG(offeringSSGFromQuery);
    if(subOfferingFromQuery)
      props.onSaveSubOffering(subOfferingFromQuery);
    if(processFromQuery)
      props.onSaveProcess(processFromQuery);
  
  }, [props.location.search]);

  useEffect(() => {
    if(!omids || omids.length === 0) return;
    if(!props.process) return;
    const selectedOMID = omids.find((o) => o.offeringName === props.process)
      ? omids.find((o) => o.offeringName === props.process).omids
      : [];

    const selectedRole = omids.find((o) => o.offeringName === props.process)
      ? omids.find((o) => o.offeringName === props.process).role
      : [];

    props.onSaveOMID(selectedOMID);
    props.onSaveRole(selectedRole);
  }, [omids, props.process]);

  const onOfferingChange = (event) => {
    const selectedValue = event.target.value;

    props.onSaveMainOffering(selectedValue);
    props.onSaveOfferingSSG(null);
    props.onSaveSubOffering(null);
    props.onSaveProcess(null);
    props.onSaveOMID(null);
    props.onSaveRole(null);
  };

  const onOfferingSSGChange = (event) => {
    const selectedValue = event.target.value;

    props.onSaveOfferingSSG(selectedValue);
    props.onSaveSubOffering(null);
    props.onSaveProcess(null);
    props.onSaveOMID(null);
    props.onSaveRole(null);
  };

  const onSubOfferingChange = (event) => {
    const selectedValue = event.target.value;

    props.onSaveSubOffering(selectedValue);
    props.onSaveProcess(null);
    props.onSaveOMID(null);
    props.onSaveRole(null);
  };

  const onProcessChange = (event) => {
    const selectedValue = event.target.value;

    props.onSaveProcess(selectedValue);
  };

  const omidList = props.omids || [];

  const offerings = props.offeringData
    ? props.offeringData.Level1Offering.map((off) => off.OfferingNameL1)
    : [];

  const allOfferingSSGs =
    props.offeringData && props.mainOffering
      ? props.offeringData.Level1Offering.find((off) => off.OfferingNameL1 === props.mainOffering)
          .Level2Offering
      : [];

  const allSubOfferings =
    allOfferingSSGs &&
    props.offeringSSG &&
    allOfferingSSGs.find((off) => off.OfferingNameL2 === props.offeringSSG)
      ? allOfferingSSGs.find((off) => off.OfferingNameL2 === props.offeringSSG).Level3Offering
      : [];

  const allProcesses =
    allSubOfferings &&
    props.subOffering &&
    allSubOfferings.find((off) => off.OfferingNameL3 === props.subOffering)
      ? allSubOfferings.find((off) => off.OfferingNameL3 === props.subOffering).Level4Offering
      : [];

  const concatinateOffering = (offering) => {
    if (!offering) return "";
    if (offering.length < 50) return offering;
    else return offering.substring(0, 50) + "...";
  };

  return (
    <div id="subnav-header-id" className="subnav-header">
      <div className="container-fluid p-0">
        <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active={showLoaderOnPage} />
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="d-flex align-items-center justify-content-between">
              <div className="offeringfilter-dropdowns d-flex align-items-center">
                <span className="mr-4">Filter By</span>
                <DropdownButton
                  menuAlign="left"
                  title={concatinateOffering(props.mainOffering) || "Select Offering"}
                  disabled={offerings.length === 0}
                  className={
                    props.mainOffering
                      ? "custom-headerdropdown selected mr-3"
                      : "custom-headerdropdown mr-3"
                  }
                >
                  <div className="offering-droplistwrapper">
                    {offerings.map((offering) => (
                      <Dropdown.Item
                        key={offering}
                        onClick={() => onOfferingChange({ target: { value: offering } })}
                        className={offering === props.mainOffering ? "active" : ""}
                      >
                        <span className={"offerings-dropdown-item-container form-check-label"} role="button" tabIndex={0}>
                          {offering}
                        </span>
                      </Dropdown.Item>
                    ))}
                  </div>
                </DropdownButton>
                <DropdownButton
                  menuAlign="left"
                  title={concatinateOffering(props.offeringSSG) || "Select Offering SSG"}
                  disabled={allOfferingSSGs.length === 0}
                  className={
                    props.offeringSSG
                      ? "custom-headerdropdown selected mr-3"
                      : "custom-headerdropdown mr-3"
                  }
                >
                  <div className="offering-droplistwrapper">
                    {allOfferingSSGs.map((offSSG) => (
                      <Dropdown.Item
                        key={offSSG.OfferingNameL2}
                        onClick={() =>
                          onOfferingSSGChange({ target: { value: offSSG.OfferingNameL2 } })
                        }
                        className={offSSG.OfferingNameL2 === props.offeringSSG ? "active" : ""}
                      >
                        <span className={"offerings-dropdown-item-container form-check-label"} role="button" tabIndex={0}>
                          {offSSG.OfferingNameL2}
                        </span>
                      </Dropdown.Item>
                    ))}
                  </div>
                </DropdownButton>
                <DropdownButton
                  menuAlign="left"
                  title={concatinateOffering(props.subOffering) || "Select Sub Offering"}
                  disabled={allSubOfferings.length === 0}
                  className={
                    props.subOffering
                      ? "custom-headerdropdown selected mr-3"
                      : "custom-headerdropdown mr-3"
                  }
                >
                  <div className="offering-droplistwrapper">
                    {allSubOfferings.map((subOff) => (
                      <Dropdown.Item
                        key={subOff.OfferingNameL2}
                        onClick={() =>
                          onSubOfferingChange({ target: { value: subOff.OfferingNameL3 } })
                        }
                        className={subOff.OfferingNameL3 === props.subOffering ? "active" : ""}
                      >
                        <span className={"offerings-dropdown-item-container form-check-label"} role="button" tabIndex={0}>
                          {subOff.OfferingNameL3}
                        </span>
                      </Dropdown.Item>
                    ))}
                  </div>
                </DropdownButton>
                <DropdownButton
                  menuAlign="left"
                  title={concatinateOffering(props.process) || "Select Process"}
                  disabled={allProcesses.length === 0}
                  className={
                    props.process
                      ? "custom-headerdropdown selected mr-3"
                      : "custom-headerdropdown mr-3"
                  }
                >
                  <div className="offering-droplistwrapper">
                    {allProcesses.map((process) => (
                      <Dropdown.Item
                        key={process}
                        onClick={() =>
                          onProcessChange({ target: { value: process.OfferingNameL4 } })
                        }
                        className={process.OfferingNameL4 === props.process ? "active" : ""}
                      >
                        <span className={"offerings-dropdown-item-container form-check-label"} role="button" tabIndex={0}>
                          {process.OfferingNameL4}
                        </span>
                      </Dropdown.Item>
                    ))}
                  </div>
                </DropdownButton>
              </div>

              <DropdownButton
                menuAlign="left"
                title={"OMID List"}
                disabled={omidList.length === 0}
                className={
                  omidList.length !== 0
                    ? "custom-headerdropdown selected mr-3"
                    : "custom-headerdropdown mr-3"
                }
              >
                <div className="offering-droplistwrapper">
                  {omidList.map((omid) => (
                    <Dropdown.Item key={omid} href="javascript:void(0);" disabled>
                      <span className={"form-check-label active"} role="button" tabIndex={0}>
                        {omid}
                      </span>
                    </Dropdown.Item>
                  ))}
                </div>
              </DropdownButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  client: state.assetInventoryReducer.assetInventoryClient,
  contract: state.assetInventoryReducer.assetInventoryContract,
  omids: state.assetInventoryReducer.assetInventoryOmids,
  mainOffering: state.assetInventoryReducer.assetInventoryMainOffering,
  offeringSSG: state.assetInventoryReducer.assetInventoryOfferingSSG,
  subOffering: state.assetInventoryReducer.assetInventorySubOffering,
  process: state.assetInventoryReducer.assetInventoryProcess,
  allOffering: state.assetInventoryReducer.assetInventoryAllOffering,
  allSubOffering: state.assetInventoryReducer.assetInventoryAllSubOffering,
  offeringData: state.assetInventoryReducer.assetInventoryOfferingData,
});

const mapDispatchToProps = (dispatch) => ({
  onSaveContract: (data) => {
    dispatch({ type: "SET_ASSET_INVENTORY_CONTRACT", payload: data });
  },
  onSaveAllOffering: (data) => {
    dispatch({ type: "SET_ASSET_INVENTORY_ALL_OFFERING", payload: data });
  },
  // onSaveAllSubOffering: (data) => {
  //   dispatch({ type: "SET_ASSET_INVENTORY_ALL_SUB_OFFERING", payload: data });
  // },
  onSaveOfferingData: (data) => {
    dispatch({ type: "SET_ASSET_INVENTORY_OFFERING_DATA", payload: data });
  },
  onSaveMainOffering: (data) => {
    dispatch({ type: "SET_ASSET_INVENTORY_MAIN_OFFERING", payload: data });
  },
  onSaveOfferingSSG: (data) => {
    dispatch({ type: "SET_ASSET_INVENTORY_OFFERING_SSG", payload: data });
  },
  onSaveSubOffering: (data) => {
    dispatch({ type: "SET_ASSET_INVENTORY_SUB_OFFERING", payload: data });
  },
  onSaveProcess: (data) => {
    dispatch({ type: "SET_ASSET_INVENTORY_PROCESS", payload: data });
  },
  onSaveOMID: (data) => {
    dispatch({ type: "SET_ASSET_INVENTORY_OMIDS", payload: data });
  },
  onSaveRole: (data) => {
    dispatch({ type: "SET_ASSET_INVENTORY_ROLE", payload: data });
  },
  onSaveClient: (data) => {
    dispatch({ type: "SET_ASSET_INVENTORY_CLIENT", payload: data });
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetAdoptionFilter));
